<script setup>
const model = defineModel();
</script>

<template>
    <input
        class="rounded border-gray-200 bg-gray-50 font-medium text-gray-700 placeholder:text-sm read-only:bg-gray-50/75 read-only:opacity-90 focus:ring-3 focus:ring-blue-500/25 focus:ring-offset-2 focus:ring-offset-transparent disabled:bg-gray-50/75 data-[size=lg]:text-lg data-[size=lg]:placeholder:text-base dark:border-gray-800 dark:bg-gray-950 dark:text-gray-300 dark:read-only:bg-gray-950/50 dark:disabled:bg-gray-950/50"
        type="text"
        data-slot="input"
        v-model="model"
    />
</template>
